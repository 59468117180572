import { SET_LOADER } from 'redux/actions/type'

interface state {
  sidebarShow: boolean
  sidebarUnfoldable: boolean
  asideShow: boolean
  theme: string
}

const initialState: state = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  asideShow: false,
  theme: 'default',
}

type args = { type?: string; [key: string]: any }

const changeStateReducer = (state = initialState, { type, ...rest }: args) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const loaderReducer = (state: boolean = false, action: ILoaderReduxAction) => {
  switch (action.type) {
    case SET_LOADER:
      return action.data
    default:
      return state
  }
}

export { changeStateReducer, loaderReducer }
