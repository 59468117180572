import { configureStore } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import { persistStore } from 'redux-persist'
import persistedReducer from 'redux/reducers'

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunkMiddleware],
})

const persistor = persistStore(store)

export { store, persistor }
